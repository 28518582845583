import { motion } from 'framer-motion';
import { twMerge } from 'tailwind-merge';

import SlideDog from '@images/partner_landing_pages/slides/slide_dog.jpg';
import SlideDoor from '@images/partner_landing_pages/slides/slide_door.jpg';
import SlideFamily from '@images/partner_landing_pages/slides/slide_family.jpg';
import SlideMan from '@images/partner_landing_pages/slides/slide_man.jpg';
import SlideMover from '@images/partner_landing_pages/slides/slide_mover.jpg';
import SlideMoving from '@images/partner_landing_pages/slides/slide_moving.jpg';
import SlidePopcorn from '@images/partner_landing_pages/slides/slide_popcorn.jpg';
import SlideSofa from '@images/partner_landing_pages/slides/slide_sofa.png';
import SlideWoman from '@images/partner_landing_pages/slides/slide_woman.jpg';

import { PopinEnergy, PopinMortgageOffer, PopinRemoval } from './popins.tsx';

const SLIDE_ZOOM_START_SCALE = 0.95;
const SLIDE_ZOOM_DURATION = 0.5;
const SLIDE_ZOOM_DELAY = 0.35;

const MOTION_VARIANTS = {
  inactive: { scale: SLIDE_ZOOM_START_SCALE },
  active: {
    scale: 1,
    transition: {
      duration: SLIDE_ZOOM_DURATION,
      delay: SLIDE_ZOOM_DELAY,
      ease: 'easeInOut',
    },
  },
};

const Slide = ({ children, hasAnimation }: any) => {
  const zIndex = hasAnimation ? 'z-[10]' : 'z-[-1]';

  return (
    <div
      className={twMerge(
        'relative z-[-1] ml-5 flex w-[60%] min-w-0 flex-shrink-0 flex-grow-0 flex-col items-center justify-center overflow-visible sm:w-[50%] md:w-[30%] lg:w-[20%] xxl:w-[15%]',
        zIndex
      )}
    >
      {children}
    </div>
  );
};

export const NewSlideOne = ({ isActive }: any) => (
  <Slide>
    <motion.div
      variants={MOTION_VARIANTS}
      initial="inactive"
      animate={!!isActive ? 'active' : 'inactive'}
      className="relative w-full bg-cover bg-center bg-no-repeat pb-[115%]"
    >
      <div
        style={{
          backgroundImage: `url(${SlideFamily})`,
        }}
        className="absolute left-0 top-0 h-full w-full rounded-3xl bg-cover bg-center bg-no-repeat"
      />
    </motion.div>
  </Slide>
);

export const NewSlideTwo = ({ isActive }: any) => (
  <Slide hasAnimation>
    <motion.div
      variants={MOTION_VARIANTS}
      initial="inactive"
      animate={!!isActive ? 'active' : 'inactive'}
      className="relative w-full bg-cover bg-center bg-no-repeat pb-[100%]"
    >
      <div
        style={{
          backgroundImage: `url(${SlideMan})`,
        }}
        className="absolute left-0 top-0 h-full w-full rounded-3xl bg-cover bg-center bg-no-repeat"
      />
    </motion.div>
    <PopinRemoval isActive={isActive} />
  </Slide>
);

export const NewSlideThree = ({ isActive }: any) => (
  <Slide>
    <motion.div
      variants={MOTION_VARIANTS}
      initial="inactive"
      animate={!!isActive ? 'active' : 'inactive'}
      className="relative w-full bg-cover bg-center bg-no-repeat pb-[85%]"
    >
      <div
        style={{
          backgroundImage: `url(${SlideMover})`,
        }}
        className="absolute left-0 top-0 h-full w-full rounded-3xl bg-cover bg-center bg-no-repeat"
      />
    </motion.div>
  </Slide>
);

export const NewSlideFour = ({ isActive }: any) => (
  <Slide hasAnimation>
    <motion.div
      variants={MOTION_VARIANTS}
      initial="inactive"
      animate={!!isActive ? 'active' : 'inactive'}
      className="relative w-full bg-cover bg-center bg-no-repeat pb-[100%]"
    >
      <div
        style={{
          backgroundImage: `url(${SlideWoman})`,
        }}
        className="absolute left-0 top-0 h-full w-full rounded-3xl bg-cover bg-center bg-no-repeat"
      />
    </motion.div>
    <PopinMortgageOffer isActive={isActive} />
  </Slide>
);

export const NewSlideFive = ({ isActive }: any) => (
  <Slide>
    <motion.div
      variants={MOTION_VARIANTS}
      initial="inactive"
      animate={!!isActive ? 'active' : 'inactive'}
      className="relative w-full bg-cover bg-center bg-no-repeat pb-[115%]"
    >
      <div
        style={{
          backgroundImage: `url(${SlideDog})`,
        }}
        className="absolute left-0 top-0 h-full w-full rounded-3xl bg-cover bg-center bg-no-repeat"
      />
    </motion.div>
  </Slide>
);

export const NewSlideSix = ({ isActive }: any) => (
  <Slide hasAnimation>
    <motion.div
      variants={MOTION_VARIANTS}
      initial="inactive"
      animate={!!isActive ? 'active' : 'inactive'}
      className="relative w-full bg-cover bg-center bg-no-repeat pb-[85%]"
    >
      <div
        style={{
          backgroundImage: `url(${SlideSofa})`,
        }}
        className="absolute left-0 top-0 h-full w-full rounded-3xl bg-cover bg-center bg-no-repeat"
      />
    </motion.div>
    {/* <PopinFoxtons isActive={isActive} /> */}
  </Slide>
);

export const NewSlideSeven = ({ isActive }: any) => (
  <Slide>
    <motion.div
      variants={MOTION_VARIANTS}
      initial="inactive"
      animate={!!isActive ? 'active' : 'inactive'}
      className="relative w-full bg-cover bg-center bg-no-repeat pb-[115%]"
    >
      <div
        style={{
          backgroundImage: `url(${SlideMoving})`,
        }}
        className="absolute left-0 top-0 h-full w-full rounded-3xl bg-cover bg-center bg-no-repeat"
      />
    </motion.div>
  </Slide>
);

export const NewSlideEight = ({ isActive }: any) => (
  <Slide>
    <motion.div
      variants={MOTION_VARIANTS}
      initial="inactive"
      animate={!!isActive ? 'active' : 'inactive'}
      className="relative w-full bg-cover bg-center bg-no-repeat pb-[85%]"
    >
      <div
        style={{
          backgroundImage: `url(${SlideDoor})`,
        }}
        className="absolute left-0 top-0 h-full w-full rounded-3xl bg-cover bg-center bg-no-repeat"
      />
    </motion.div>
  </Slide>
);

export const NewSlideNine = ({ isActive }: any) => (
  <Slide hasAnimation>
    <motion.div
      variants={MOTION_VARIANTS}
      initial="inactive"
      animate={!!isActive ? 'active' : 'inactive'}
      className="relative w-full bg-cover bg-center bg-no-repeat pb-[100%]"
    >
      <div
        style={{
          backgroundImage: `url(${SlidePopcorn})`,
        }}
        className="absolute left-0 top-0 h-full w-full rounded-3xl bg-cover bg-center bg-no-repeat"
      />
    </motion.div>
    <PopinEnergy isActive={isActive} />
  </Slide>
);
