import { useMemo } from 'react';
import { Link } from 'react-router-dom';

import { ProductLinks, SocialLinks } from '@constants/index';

import { MoveType } from '@features/profile/profile.types';

import { HouseCross, Plus, StoreBag, WhatsApp } from '@images/icons';

import { Tip } from '../types';

export const WelcomeTip = (status?: string): Tip => {
  const friendlyStatus = () => {
    switch (status) {
      case MoveType.BUYER:
        return 'Buyers';
      case MoveType.SELLER:
        return 'Sellers';
      case MoveType.TENANCY:
        return 'Renters';
      default:
        return 'Our customers';
    }
  };

  return {
    id: 'welcome',
    isVisible: true,
    text: 'Welcome to Your Home Account',
    iconBackground: 'bg-home',
    icon: <HouseCross className="h-6 fill-white" />,
    loose: true,
    children: (
      <p>
        {friendlyStatus()} love how Home simplifies the list of moving admin and gets tasks done
        seamlessly.
      </p>
    ),
  };
};

export const WhatsAppTip = (whatsAppLink?: string) => ({
  id: 'whatsapp',
  isVisible: true,
  text: !!whatsAppLink
    ? 'Join the WhatsApp group for your sale'
    : 'You can add your Home Team on WhatsApp 🙌',
  iconBackground: 'bg-[#27D045]',
  icon: <WhatsApp className="ml-0.5 mt-0.5 h-6 fill-white" />,
  loose: true,
  children: (
    <Link
      to={whatsAppLink || SocialLinks.WHATSAPP}
      target="_blank"
      className="button-animate rounded-xl px-10 py-3 font-medium"
      rel="noreferrer"
    >
      {!!whatsAppLink ? 'Join WhatsApp group' : 'Add us on WhatsApp'}
    </Link>
  ),
});

export const StoreTip = {
  id: 'store',
  isVisible: true,
  text: 'Make it home with the Home Store',
  iconBackground: 'bg-home',
  icon: <StoreBag className="ml-0.5 mt-0.5 h-6 fill-white" />,
  loose: true,
  children: (
    <Link
      to={ProductLinks.STORE.MAIN}
      target="_blank"
      className="button-animate rounded-xl px-10 py-3 font-medium"
      rel="noreferrer"
    >
      Check out the Home Store
    </Link>
  ),
};

export const AddToHomeScreenTip = (): Tip => {
  const isMobile = useMemo(() => /Mobi|Android/i.test(window.navigator.userAgent), []);

  return {
    id: 'add-home-screen',
    isVisible: true,
    text: 'You can install Home on your home screen',
    iconBackground: 'bg-home',
    icon: <Plus className="h-6 fill-white" />,
    loose: true,
    children: isMobile ? (
      <p>Tap your browser menu and select &quot;Add to Home Screen&quot; to install Home.</p>
    ) : (
      <p>Login with your mobile device to get started.</p>
    ),
  };
};
