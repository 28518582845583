import { useSelector } from 'react-redux';

import { selectProfile } from '@features/profile/profile';

import { convertAddressToSingleLine } from '@utils/convertAddress';

import { MovingDashboard } from './Moving';
import { NotMovingDashboard } from './NotMoving';

export const Dashboard = () => {
  const profile = useSelector(selectProfile).value;

  const initialProperty = profile?.properties?.[0];

  const address = initialProperty ? convertAddressToSingleLine(initialProperty) : '';
  const postcode = initialProperty?.postcode ?? '';
  const moveStatus = initialProperty?.moveStatus ?? '';

  const isMoving = !!postcode && !!address && moveStatus !== 'COMPLETED';

  if (!isMoving || !initialProperty) {
    return <NotMovingDashboard postcode={postcode} />;
  } else {
    return <MovingDashboard property={initialProperty} />;
  }
};
