import { useSelector } from 'react-redux';
import { useParams } from 'react-router';

import { HomeTeamBlockAus } from '@components/Australia/HomeTeamBlock';
import { MoveWizardAus } from '@components/Australia/MoveWizardAus';
import { MoveWizard } from '@components/MoveWizard';
import { MovingTile } from '@components/MovingTile';

import { selectProfile } from '@features/profile/profile';

import { isAustraliaBrand, PartnerBrand } from '@utils/getBrandingConfig';

export const Move = () => {
  const { id } = useParams<{ id: string }>();
  const profile = useSelector(selectProfile).value;

  const property = !!id
    ? profile?.properties.find(property => property.propertyId === id)
    : undefined;

  if (!property) {
    return (
      <div className="mx-auto min-h-[calc(100vh-390px)] max-w-5xl text-center">
        <h1 className="w-full py-16 text-center text-4xl font-medium md:py-32 lg:py-48">
          Property not found
        </h1>
      </div>
    );
  }

  const isAustralia = isAustraliaBrand(property.partnerCode as PartnerBrand);

  return (
    <main className="mx-auto flex w-full max-w-6xl flex-col gap-12 px-5 pb-12 pt-5">
      <MovingTile property={property} />
      <section className="mx-auto flex w-full max-w-6xl rounded-2xl">
        {isAustralia ? <MoveWizardAus property={property} /> : <MoveWizard property={property} />}
      </section>
      {isAustralia && <HomeTeamBlockAus />}
    </main>
  );
};
