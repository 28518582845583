import * as Sentry from '@sentry/react';
import { Link } from 'react-router-dom';

import { HomeTeamBlockAus } from '@components/Australia/HomeTeamBlock';
import { HomeTeam } from '@components/HomeTeamBlock';
import { MovingTile } from '@components/MovingTile';

import { HOME_EMAIL } from '@constants/index';

import { Address, MoveType } from '@features/profile/profile.types';

import { WhatsApp } from '@images/icons';

import { NotFound } from '@pages/notFound';

import { isAustraliaBrand, PartnerBrand } from '@utils/getBrandingConfig';

import { NextSteps } from './NextSteps';
import { NextStepsAus } from './NextStepsAus';
import { QuickTips } from './QuickTips';

type MovingDashboardProps = {
  property: Address;
};

export const MovingDashboard = ({ property }: MovingDashboardProps) => {
  const isAustralia =
    !!property.partnerCode && isAustraliaBrand(property.partnerCode as PartnerBrand);

  const isChestertons = property.partnerCode === PartnerBrand.Chestertons;

  const whatsappLink = property.whatsAppLink || '';

  if (!!isAustralia && property.moveType === MoveType.TENANCY) {
    Sentry.captureMessage('Tenancy move in Australia');

    return <NotFound />;
  }

  if (!!isAustralia)
    return (
      <main
        className={`mx-auto flex w-full max-w-6xl flex-col gap-8 px-5 pb-12 pt-5 md:min-h-[calc(100vh-398px)]`}
      >
        <div className="grid w-full grid-cols-1 gap-8 md:grid-cols-5">
          {property && <MovingTile property={property} includeLink />}
          <QuickTips />
        </div>
        <NextStepsAus />
        {!!whatsappLink && (
          <div className="flex w-full flex-col justify-center gap-2 rounded-xl bg-white p-6 shadow-homeLargeLight md:gap-4 md:p-8">
            <WhatsApp className="mx-auto h-12 fill-[#27D045]" />
            <h2 className="text-center text-2xl font-medium md:text-3xl">
              Speak to your Home Team on WhatsApp
            </h2>
            <Link to={whatsappLink} className="text-center underline hover:no-underline">
              Open the WhatsApp group for your sale
            </Link>
          </div>
        )}
        <HomeTeamBlockAus />
      </main>
    );

  return (
    <main
      className={`mx-auto flex w-full max-w-6xl flex-col gap-8 px-5 pb-12 pt-5 md:min-h-[calc(100vh-398px)]`}
    >
      <div className="grid w-full grid-cols-1 gap-8 md:grid-cols-5">
        {property && <MovingTile property={property} includeLink />}
        <QuickTips />
      </div>
      <NextSteps />

      <HomeTeam email={isChestertons ? 'chestertons@home.cc' : HOME_EMAIL} />
    </main>
  );
};
