import { Link } from 'react-router-dom';

import { Spinner } from '@components/Spinner';

import { Routes } from '@constants/index';

import { useBroadbandComparison } from '@hooks/useBroadbandComparison';

import { Broadband } from '@images/icons';

import { formatSpeed } from '@utils/formatBroadbandSpeed';

export const BroadbandTip = (postcode: string) => ({
  id: 'broaband',
  isVisible: true,
  text: 'Get connected',
  iconBackground: 'bg-home',
  icon: <Broadband className="h-6 fill-white" />,
  children: <Content postcode={postcode} />,
});

export const Content = ({ postcode }: { postcode: string }) => {
  const { data, error, loading } = useBroadbandComparison(postcode);

  if (loading)
    return (
      <div className="flex w-full items-center justify-center">
        <Spinner colour="black" />
      </div>
    );

  if (!data || error)
    return (
      <div className="flex w-full flex-col items-center justify-center gap-3 sm:gap-5">
        <span className="w-full text-center text-lg font-medium sm:text-xl">
          Couldn't find cheapest deal for {postcode}
        </span>

        <Link
          to={Routes.SERVICES_BROADBAND}
          className="button-animate rounded-xl px-10 py-2 font-medium"
          rel="noreferrer"
        >
          View all deals
        </Link>
      </div>
    );

  const { cheapest } = data;

  const { speed, unit } = formatSpeed(cheapest.downloadSpeed);

  return (
    <div className="flex flex-col items-start gap-2 sm:gap-0 md:gap-2">
      <span className="hidden font-medium md:block">Your cheapest deal</span>

      <div className="flex flex-row items-center justify-center gap-5">
        <div
          style={{ backgroundImage: `url(${cheapest.network.logo})` }}
          className="h-12 w-20 rounded-lg bg-contain bg-center bg-no-repeat"
        />
        <div className="flex items-end gap-1 font-heading font-medium">
          <span className="text-2xl">{speed}</span>
          {unit}
        </div>
        <div className="flex items-end gap-1 font-heading font-medium">
          <span className="text-2xl">£{cheapest.effectiveMonthlyCost}</span>
          Per month
        </div>
      </div>
      <div className="flex items-center gap-4">
        <Link
          to={cheapest.outlink}
          target="_blank"
          className="button-animate rounded-xl px-8 py-2 font-medium"
          rel="noreferrer"
        >
          Get deal
        </Link>

        <Link to={Routes.SERVICES_BROADBAND} className="underline hover:no-underline">
          View all deals
        </Link>
      </div>
    </div>
  );
};
