import { motion, AnimatePresence } from 'framer-motion';

import { ReactComponent as WhatsAppIcon } from './assets/popin_whatsapp.svg';
// import PopupOneA from './assets/popup-1a.webp';
// import { ReactComponent as PopupOneB } from './assets/popup-1b.svg';
import { ReactComponent as PopupTwoA } from './assets/popup-2a.svg';

// export const PopinFoxtons = ({ isActive }: any) => (
//   <AnimatePresence>
//     {!!isActive && (
//       <div className="left-1/5 absolute top-1/2 z-20 w-[320px] sm:left-1/3 sm:w-[360px] md:left-1/2 md:top-2/3">
//         <motion.div
//           key="popup-1"
//           exit={{ opacity: 0, y: 20, transition: { delay: 0.5 } }}
//           initial={{ scale: 0 }}
//           animate={{ scale: 1, transition: { delay: 0.75 } }}
//           transition={{
//             type: 'spring',
//             stiffness: 260,
//             damping: 20,
//           }}
//         >
//           <div className="flex h-10 items-center gap-3 overflow-hidden rounded-full border-2 border-white bg-white pr-5 shadow-md sm:h-12">
//             <img
//               className="h-full w-24 rounded-r-full sm:w-28"
//               src={PopupOneA}
//               alt="Foxtons logo"
//               aria-label="Estate Agent Logo"
//               aria-hidden
//             />
//             <h3 className="font-aktiv flex items-center gap-5 text-center text-base sm:text-lg">
//               <span>£688,750</span>
//               <div className="flex items-center gap-0.5 text-xs sm:gap-1 sm:text-sm">
//                 <PopupOneB className="h-4 sm:h-5" /> Sale agreed
//               </div>
//             </h3>
//           </div>
//         </motion.div>
//       </div>
//     )}
//   </AnimatePresence>
// );

export const PopinMortgageOffer = ({ isActive }: any) => (
  <AnimatePresence>
    {!!isActive && (
      <motion.div
        className="left-1/5 absolute top-1/2 z-20 flex w-[300px] items-end gap-2 md:left-1/3 md:w-[370px]"
        initial={{
          opacity: 0,
        }}
        animate={{
          opacity: 1,
          transition: {
            delay: 0.75,
          },
        }}
        exit={{ opacity: 0, y: 20 }}
      >
        <motion.div
          initial={{
            y: 20,
            opacity: 0,
          }}
          animate={{
            y: 0,
            opacity: 1,
            transition: {
              delay: 1,
            },
          }}
        >
          <div className="flex h-10 w-10 items-center justify-center rounded-full bg-white">
            <PopupTwoA className="w-5 fill-primary" />
          </div>
        </motion.div>
        <motion.div
          initial={{
            y: 20,
            opacity: 0,
          }}
          animate={{
            y: 0,
            opacity: 1,
            transition: {
              delay: 1.25,
            },
          }}
        >
          <div className="font-aktiv flex flex-col gap-2">
            <div className="rounded-r-xl rounded-tl-xl bg-white px-4 py-2 text-lg">
              Great news, your mortgage offer is ready to sign. <u>Review and sign here</u>
            </div>
            <span className="text-white">3:02PM</span>
          </div>
        </motion.div>
      </motion.div>
    )}
  </AnimatePresence>
);

export const PopinRemoval = ({ isActive }: any) => (
  <AnimatePresence>
    {!!isActive && (
      <motion.div
        className="left-1/5 absolute top-1/2 z-20 flex w-[300px] items-end gap-2 md:left-1/3 md:w-[370px]"
        initial={{
          opacity: 0,
        }}
        animate={{
          opacity: 1,
          transition: {
            delay: 0.75,
          },
        }}
        exit={{ opacity: 0, y: 20 }}
      >
        <motion.div
          initial={{
            y: 20,
            opacity: 0,
          }}
          animate={{
            y: 0,
            opacity: 1,
            transition: {
              delay: 1,
            },
          }}
        >
          <div className="flex h-10 w-10 items-center justify-center rounded-full bg-white">
            <PopupTwoA className="w-5 fill-primary" />
          </div>
        </motion.div>
        <motion.div
          initial={{
            y: 20,
            opacity: 0,
          }}
          animate={{
            y: 0,
            opacity: 1,
            transition: {
              delay: 1.25,
            },
          }}
        >
          <div className="font-aktiv flex flex-col gap-2">
            <div className="rounded-r-xl rounded-tl-xl bg-white px-4 py-2 text-lg">
              Your removal is booked for the 12th
            </div>
            <span className="text-white">2:33PM</span>
          </div>
        </motion.div>
      </motion.div>
    )}
  </AnimatePresence>
);

export const PopinEnergy = ({ isActive }: any) => (
  <AnimatePresence>
    {!!isActive && (
      <motion.div
        className="left-1/5 absolute top-1/2 z-20 flex w-[300px] items-end gap-2 md:left-1/3 md:w-[370px]"
        initial={{
          opacity: 0,
        }}
        animate={{
          opacity: 1,
          transition: {
            delay: 0.75,
          },
        }}
        exit={{ opacity: 0, y: 20 }}
      >
        <motion.div
          initial={{
            y: 20,
            opacity: 0,
          }}
          animate={{
            y: 0,
            opacity: 1,
            transition: {
              delay: 1,
            },
          }}
        >
          <div className="flex h-8 w-8 items-center justify-center rounded-full bg-[#27D045]">
            <WhatsAppIcon />
          </div>
        </motion.div>
        <motion.div
          initial={{
            y: 20,
            opacity: 0,
          }}
          animate={{
            y: 0,
            opacity: 1,
            transition: {
              delay: 1.25,
            },
          }}
        >
          <div className="font-aktiv flex flex-col gap-2">
            <div className="rounded-r-xl rounded-tl-xl bg-white px-4 py-2 text-lg">
              We&apos;ve found a cheaper energy tariff for you - swap and save £72 per month 🙌
            </div>
            <span className="text-white">4:16PM</span>
          </div>
        </motion.div>
      </motion.div>
    )}
  </AnimatePresence>
);
