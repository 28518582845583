import * as Sentry from '@sentry/react';
import { useSelector } from 'react-redux';

import { HomeTeamBlockAus } from '@components/Australia/HomeTeamBlock';
import { HomeTeam } from '@components/HomeTeamBlock';

import { selectProfile } from '@features/profile/profile';

import { isAustraliaBrand, PartnerBrand } from '@utils/getBrandingConfig';

import { AllServices } from './AllServices';
import { BroadbandTile } from './Broadband';
import { Removals } from './Removals';

type NotMovingProps = {
  postcode: string;
};

export const NotMovingDashboard = ({ postcode }: NotMovingProps) => {
  const { value: profile } = useSelector(selectProfile);
  const initialProperty = profile?.properties?.[0];

  const isAustralia =
    !!initialProperty?.partnerCode && isAustraliaBrand(initialProperty.partnerCode as PartnerBrand);

  if (!!isAustralia) {
    Sentry.captureMessage('User not moving in Australia');

    return (
      <main className="mx-auto flex w-full max-w-6xl flex-col gap-8 px-5 pb-12 pt-5">
        <div className="my-12 flex flex-col items-center justify-center gap-5">
          <h1 className="text-3xl font-bold">It looks like something went wrong</h1>
          <p className="text-center text-lg">
            We can't find your address, please refresh the page and try again.
          </p>
          <p className="text-center text-lg">
            If the problem still persists, please contact our customer service team.
          </p>
        </div>
        <HomeTeamBlockAus />
      </main>
    );
  }

  return (
    <main className="mx-auto flex w-full max-w-6xl flex-col gap-8 px-5 pb-12 pt-5">
      {postcode && <BroadbandTile postcode={postcode} />}
      <Removals />
      <AllServices />
      <HomeTeam />
    </main>
  );
};
