import { ReactComponent as AxaLogo } from '@images/partner_landing_pages/partner_logos/p_axa.svg';
import Bishops from '@images/partner_landing_pages/partner_logos/p_bishops-move.png';
import Enterprise from '@images/partner_landing_pages/partner_logos/p_enterprise.png';
import Eon from '@images/partner_landing_pages/partner_logos/p_eon.png';
import { ReactComponent as RingLogo } from '@images/partner_landing_pages/partner_logos/p_ring.svg';
import { ReactComponent as SkyLogo } from '@images/partner_landing_pages/partner_logos/p_sky.svg';
import { ReactComponent as TalktalkLogo } from '@images/partner_landing_pages/partner_logos/p_talktalk.svg';
import { ReactComponent as VodafoneLogo } from '@images/partner_landing_pages/partner_logos/p_vodafone.svg';

export const Partners = () => {
  return (
    <section className="mx-auto flex max-w-7xl flex-col gap-8 px-5 pb-16 pt-12">
      <p className="text-center text-xl">We&apos;re partnered with all your favourites</p>
      <div className="flex flex-wrap items-center justify-center gap-8 md:gap-10">
        <img src={Enterprise} alt="Enterprise" width={96} height={19} />
        <AxaLogo width={47} height={47} />
        <VodafoneLogo width={111} height={29} />
        <SkyLogo width={75} height={47} />
        <TalktalkLogo width={121} height={26} />
        <img src={Eon} alt="Eon" width={85} height={43} />
        <img src={Bishops} alt="Bishops" width={74} height={73} />

        <RingLogo width={82} height={51} />
      </div>
    </section>
  );
};
