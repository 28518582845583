import React from 'react';

import { LinkButton } from '@components/Button';
import { Spinner } from '@components/Spinner';

import { Broadband } from '@images/icons';

import { formatSpeed } from '@utils/formatBroadbandSpeed';

import {
  HomeboxBroadbandProduct,
  useBroadbandComparison,
} from '../../../../hooks/useBroadbandComparison';

interface BroadbandTileProps {
  postcode: string;
}

const Deal = React.memo(
  ({ network, downloadSpeed, effectiveMonthlyCost, outlink }: HomeboxBroadbandProduct) => {
    const { speed, unit } = formatSpeed(downloadSpeed);

    return (
      <div className="flex w-full flex-col gap-2">
        <div className="flex flex-col items-center justify-center gap-6 sm:flex-row">
          <div
            style={{ backgroundImage: `url(${network.logo})` }}
            className="h-14 w-28 rounded-lg bg-contain bg-center bg-no-repeat sm:h-16 lg:w-32"
          />
          <div className="flex items-end gap-1 font-heading font-medium leading-none">
            <span className="-mb-0.5 text-[24px] md:-mb-1 md:text-[30px]">{speed}</span>
            {unit}
          </div>
          <div className="flex items-end gap-1 font-heading font-medium leading-none">
            <span className="-mb-0.5 text-[24px] md:-mb-1 md:text-[30px]">
              £{effectiveMonthlyCost}
            </span>
            Per month
          </div>
        </div>
        <div className="flex w-full justify-center">
          <LinkButton href={outlink} target="_blank" customStyles="!py-3 !h-auto !text-base">
            Get deal
          </LinkButton>
        </div>
      </div>
    );
  }
);

export const BroadbandTile: React.FC<BroadbandTileProps> = ({ postcode }) => {
  const { data, error, loading } = useBroadbandComparison(postcode);

  if (loading)
    return (
      <div className="flex w-full items-center justify-center px-8 pb-20 pt-32">
        <Spinner colour="black" />
      </div>
    );

  if (error || !data) return null;

  const { cheapest, fastest } = data;
  const { speed: fastestSpeed, unit: fastestUnit } = formatSpeed(fastest.downloadSpeed);

  return (
    <div className="flex w-full flex-col justify-center gap-5 overflow-hidden rounded-2xl bg-white p-5 shadow-homeLarge md:p-8">
      <h2 className="flex flex-col items-center justify-center gap-4 px-8 text-center text-2xl font-medium md:flex-row">
        <Broadband className="h-10 fill-primary" />
        <span>
          Get up to {fastestSpeed}&nbsp;
          <span className="text-xl">{fastestUnit}</span> broadband!
        </span>
      </h2>

      <p className="px-8 text-center text-lg">
        We&apos;ve checked the market for you at <span className="underline">{postcode}</span>.
        Compare plans and get your home connected.
      </p>
      <div className="grid grid-cols-1 gap-12 px-8 md:grid-cols-2 md:gap-8">
        <div className="flex flex-col items-center gap-1">
          <span className="text-center text-lg font-medium">Your cheapest deal:</span>
          <Deal {...cheapest} />
        </div>
        <div className="flex flex-col items-center gap-1">
          <span className="text-center text-lg font-medium">Your fastest deal:</span>
          <Deal {...fastest} />
        </div>
      </div>
      <div className="-mt-6 flex w-full flex-col items-center">
        <div className="flex w-full max-w-2xl items-center px-8">
          <span className="h-px w-full flex-grow bg-gray-300"></span>
          <span className="shrink-0 p-8 text-center font-heading text-xl text-gray-600">
            Looking for something more specific?
          </span>
          <span className="h-px w-full flex-grow bg-gray-300"></span>
        </div>
        <div className="flex flex-col items-center text-gray-700">
          <a
            href="/services/broadband"
            rel="noreferrer"
            className="mt-auto flex rounded-2xl border-2 border-black bg-white px-10 py-3 text-center text-base font-medium text-black transition-all hover:bg-black hover:text-white md:px-12"
          >
            View all deals
          </a>
        </div>
      </div>
    </div>
  );
};
