import { Link } from 'react-router-dom';

import { Spinner } from '@components/Spinner';

import { getTenantInsuranceLink, ProductLinks, Routes } from '@constants/index';

import { useBroadbandComparison } from '@hooks/useBroadbandComparison';
import { useEnergyComparison } from '@hooks/useEnergyComparison';

import BundleLogos from '@images/bundles-logos.png';
import DefaqtoLogo from '@images/defaqto.png';
import { Broadband, Bulb, HouseCross, Insurance, Removals, TickIsolated } from '@images/icons';
import RemovalsAnyVan from '@images/removals-card_anyvan.png';
import RemovalsBishops from '@images/removals-card_bishops.png';
import RemovalsHumphreys from '@images/removals-card_humphreys.png';

import { formatSpeed } from '@utils/formatBroadbandSpeed';

export const TenantsInsuranceCard = ({ agent }: { agent?: string }) => (
  <div className="flex flex-col items-start gap-4">
    <div className="flex items-center gap-3">
      <Insurance className="h-8 fill-black" />
      <h3 className="text-xl font-medium">Tenant Insurance</h3>
    </div>
    <h4 className="text-2xl font-medium">
      Get peace of mind with 5* Defaqto rated tenant insurance
    </h4>
    <p>
      Protect your belongings at your new home and get covered with Safe House Insurance. Made for
      renters, it offers top-quality insurance with a <strong>5* Defaqto rating.</strong>
    </p>
    <div className="flex items-center gap-4 py-2">
      <img src={DefaqtoLogo} alt="Defaqto" className="w-24" />
      <span className="font-heading text-lg">Insurance made for renters</span>
    </div>
    <Link
      to={getTenantInsuranceLink(agent || '')}
      target="_blank"
      className="button-animate rounded-xl px-10 py-3 font-medium"
      rel="noreferrer"
    >
      Get a quote online
    </Link>
    <Link
      to={ProductLinks.TENANTS_INSURANCE}
      target="_blank"
      className="py-1 font-medium underline hover:no-underline"
      rel="noreferrer"
    >
      Request a callback to discuss your needs
    </Link>
  </div>
);

export const BuyersInsuranceCard = () => (
  <div className="flex flex-col items-start gap-4">
    <div className="flex items-center gap-3">
      <Insurance className="h-8 fill-black" />
      <h3 className="text-xl font-medium">Buyers Insurance</h3>
    </div>
    <h4 className="text-2xl font-medium">
      Home Buyers Protection from £74<span className="text-lg">.00</span>
    </h4>
    <p>
      Did you know you can cover your legal, survey and mortgage costs should your purchase fall
      through.
    </p>
    <Link
      to={ProductLinks.BUYER_INSURANCE}
      target="_blank"
      className="button-animate rounded-xl px-10 py-3 font-medium"
      rel="noreferrer"
    >
      Protect your move fees
    </Link>
  </div>
);

export const SellersInsuranceCard = () => (
  <div className="flex flex-col items-start gap-4">
    <div className="flex items-center gap-3">
      <Insurance className="h-8 fill-black" />
      <h3 className="text-xl font-medium">Sellers Insurance</h3>
    </div>
    <h4 className="text-2xl font-medium">
      Home Sellers Protection from £69<span className="text-lg">.00</span>
    </h4>
    <p>Did you know you can cover your legal costs should your sale fall through.</p>
    <Link
      to={ProductLinks.SELLER_INSURANCE}
      target="_blank"
      className="button-animate rounded-xl px-10 py-3 font-medium"
      rel="noreferrer"
    >
      Protect your move fees
    </Link>
  </div>
);

export const BroadbandCard = ({ postcode }: any) => {
  const { data, error, loading } = useBroadbandComparison(postcode);

  if (loading)
    return (
      <div className="flex w-full items-center justify-center px-8 py-16">
        <Spinner colour="black" />
      </div>
    );

  if (error || !data)
    return (
      <div className="flex flex-col items-start gap-4">
        <div className="flex items-center gap-3">
          <Broadband className="h-8 fill-black" />
          <h3 className="text-xl font-medium">Broadband</h3>
        </div>

        <span className="flex text-center text-xl font-medium">
          Couldn't find cheapest or fastest deal for {postcode}
        </span>

        <Link
          to={Routes.SERVICES_BROADBAND}
          className="w-full text-center underline hover:no-underline"
        >
          View all deals
        </Link>
      </div>
    );

  const { cheapest, fastest } = data;
  const { speed, unit } = formatSpeed(cheapest.downloadSpeed);
  const { speed: fastestSpeed, unit: fastestUnit } = formatSpeed(fastest.downloadSpeed);

  return (
    <div className="flex w-full flex-col items-start gap-4">
      <div className="flex items-center gap-3">
        <Broadband className="h-8 fill-black" />
        <h3 className="text-xl font-medium">Broadband</h3>
      </div>

      <h4 className="text-2xl font-medium">Get connected</h4>

      <div className="flex flex-col items-start gap-2">
        <span className="font-medium">Your cheapest deal:</span>

        <div className="flex flex-row items-center justify-center gap-4 sm:gap-5">
          <div
            style={{ backgroundImage: `url(${cheapest.network.logo})` }}
            className="h-14 w-28 rounded-lg bg-contain bg-center bg-no-repeat sm:h-16 lg:w-32"
          />
          <div className="flex items-end gap-1 font-heading text-sm font-medium leading-none sm:text-base">
            <span className="-mb-0.5 text-[24px] md:-mb-1 md:text-[30px]">{speed}</span>
            {unit}
          </div>
          <div className="flex items-end gap-1 font-heading text-sm font-medium leading-none sm:text-base">
            <span className="-mb-0.5 text-[24px] md:-mb-1 md:text-[30px]">
              £{cheapest.effectiveMonthlyCost}
            </span>
            Per month
          </div>
        </div>
      </div>

      <div className="flex flex-col items-start gap-2">
        <span className="font-medium">Your fastest deal:</span>

        <div className="flex flex-row items-center justify-center gap-4 sm:gap-5">
          <div
            style={{ backgroundImage: `url(${fastest.network.logo})` }}
            className="h-14 w-28 rounded-lg bg-contain bg-center bg-no-repeat sm:h-16 lg:w-32"
          />
          <div className="flex items-end gap-1 font-heading text-sm font-medium leading-none sm:text-base">
            <span className="-mb-0.5 text-[24px] md:-mb-1 md:text-[30px]">{fastestSpeed}</span>
            {fastestUnit}
          </div>
          <div className="flex items-end gap-1 font-heading text-sm font-medium leading-none sm:text-base">
            <span className="-mb-0.5 text-[24px] md:-mb-1 md:text-[30px]">
              £{fastest.effectiveMonthlyCost}
            </span>
            Per month
          </div>
        </div>
      </div>
      <Link
        to={cheapest.outlink}
        target="_blank"
        className="button-animate rounded-xl px-10 py-3 font-medium"
        rel="noreferrer"
      >
        Get deal
      </Link>

      <Link to={Routes.SERVICES_BROADBAND} className="underline hover:no-underline">
        View all deals
      </Link>
    </div>
  );
};

export const RemovalsCard = () => (
  <div className="flex flex-col items-start gap-4">
    <div className="flex items-center gap-3">
      <Removals className="h-10 fill-black" />
      <h3 className="text-xl font-medium">Removals</h3>
    </div>
    <h4 className="text-2xl font-medium">Book your removals or van hire</h4>
    <p>
      Our reliable partners are on hand to make this your easiest move yet with over 100 years
      experience.
    </p>
    <div className="flex items-center gap-6 py-2">
      <img src={RemovalsHumphreys} alt="Humphreys" className="w-[122px]" />
      <img src={RemovalsBishops} alt="Bishops" className="w-[68px]" />
      <img src={RemovalsAnyVan} alt="AnyVan" className="w-[110px]" />
    </div>
    <ul className="flex flex-col gap-3">
      <li className="flex items-center gap-2">
        <TickIsolated className="h-5 fill-primary" />
        Dedicated support
      </li>
      <li className="flex items-center gap-2">
        <TickIsolated className="h-5 fill-primary" />
        Free compensation cover
      </li>
      <li className="flex items-center gap-2">
        <TickIsolated className="h-5 fill-primary" />
        Driver tracking
      </li>
    </ul>
    <Link
      to={ProductLinks.REQUEST_CHAT_REMOVALS}
      target="_blank"
      className="button-animate rounded-xl px-10 py-3 font-medium"
      rel="noreferrer"
    >
      Request a quote
    </Link>
  </div>
);

export const BundlesCard = ({ agent }: { agent?: string }) => (
  <div className="flex flex-col items-start gap-6">
    <div className="flex items-center gap-3">
      <HouseCross className="h-8 fill-black" />
      <h3 className="text-xl font-medium">Home Bills Bundle</h3>
    </div>
    <h4 className="text-2xl font-medium">Save time and money with a Home bills bundle</h4>
    <p>
      One payment, all your bills sorted. Create a bundle of all your bills to save more and
      simplify your home admin with just one easy bill to manage.
    </p>
    <p>Living with other bill payers? You can split the bills between you too!</p>
    <img src={BundleLogos} alt="Bundles" className="w-full max-w-sm object-cover" />
    <Link
      to={ProductLinks.BUNDLES}
      target="_blank"
      className="button-animate rounded-xl px-10 py-3 font-medium"
      rel="noreferrer"
    >
      Build my Home Bundle
    </Link>
  </div>
);
