import { Camera, Clipboard, Search } from '@images/icons';

export const InspectTip = {
  id: 'inspect',
  isVisible: true,
  text: 'Inspect Safety Features',
  iconBackground: 'bg-home',
  icon: <Search className="h-6 fill-white" />,
  loose: true,
  children: (
    <p>
      Test smoke alarms, carbon monoxide detectors, and locks to ensure they&apos;re working
      properly.
    </p>
  ),
};

export const DocumentTip = {
  id: 'document',
  isVisible: true,
  text: 'Document Everything',
  iconBackground: 'bg-home',
  icon: <Camera className="h-6 fill-white" />,
  loose: true,
  children: (
    <p>
      Take photos on your phone with a date stamp of anything you notice, ensure its in your check
      in report.
    </p>
  ),
};

export const MaintenanceTip = {
  id: 'maintenance',
  isVisible: true,
  text: 'Know Your Maintenance Contacts',
  iconBackground: 'bg-home',
  icon: <Clipboard className="h-6 fill-white" />,
  loose: true,
  children: (
    <p>
      Save your landlord&apos;s or property manager&apos;s contact details for any repairs or
      emergencies.
    </p>
  ),
};
