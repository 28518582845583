import { Link, useParams } from 'react-router-dom';

import { HomeLogo } from '@components/HomeLogo';

import { SocialLinks } from '@constants/index';

import { WhatsApp } from '@images/icons';

import { NotFound } from '@pages/notFound';

import { getBrandingConfig, PartnerBrand } from '@utils/getBrandingConfig';

import { UnauthenticatedFooter } from '../../components/UnauthenticatedFooter';

import { IntroCarousel } from './MarketingCarousel';
import { Partners } from './Partners';
import { Services } from './Services';

export const WelcomePage = () => {
  const { id: partner } = useParams();

  const partnerDetails = getBrandingConfig(partner);

  if (
    !partnerDetails ||
    ![
      PartnerBrand.Chestertons,
      PartnerBrand.Anaconda,
      PartnerBrand.Beresfords,
      PartnerBrand.Bishops,
      PartnerBrand.Upstix,
    ].includes(partnerDetails.id)
  ) {
    return <NotFound />;
  }

  localStorage.setItem('referringPartner', partnerDetails.id);

  const queries = new URLSearchParams(window.location.search);
  const queryName = queries.get('name') || '';
  const queryAddress = queries.get('address') || '';
  const queryType = queries.get('type') || '';

  const formattedName = decodeURIComponent(queryName);
  const formattedAddress = decodeURIComponent(queryAddress);

  const introText =
    !!formattedName && !!formattedAddress
      ? `Hi 👋 ${formattedName}, let's get you moving to ${formattedAddress}`
      : '';

  return (
    <div className="mx-auto flex min-h-screen w-full flex-col items-center justify-center bg-white lg:gap-6">
      <header className="flex w-full max-w-7xl flex-col items-center gap-7 p-6 pt-12 lg:pt-16">
        {!!partnerDetails.images.homeX ? (
          <img
            src={partnerDetails.images.homeX.url}
            alt={`Home X ${partnerDetails.title}`}
            height={partnerDetails.images.homeX.height}
            width={partnerDetails.images.homeX.width}
          />
        ) : (
          <HomeLogo />
        )}
        {!!introText && (
          <span className="max-w-2xl text-center text-lg font-medium">{introText}</span>
        )}
        <div
          className={`flex w-full flex-col items-center gap-9 rounded-2xl px-8 py-14 text-white ${partnerDetails.style.bg} lg:pb-48`}
        >
          <h1 className="max-w-3xl text-center text-2xl font-medium !leading-normal text-white md:text-3xl lg:text-3xl xl:max-w-2xl">
            Meet Home- the free moving service for {partnerDetails.title} customers
          </h1>
          <p className="max-w-3xl text-center text-lg font-light">
            Switch utilities, book removals, set up your council tax and everything in-between. Book
            through your account or simply connect on WhatsApp and let us do the hard work for you.
          </p>
          <div className="flex w-full flex-col items-center justify-center gap-12 py-3 sm:flex-row lg:pb-0">
            <Link
              to="/signup"
              className="flex h-12 w-full max-w-[268px] items-center justify-center rounded-full border-none bg-primary font-medium text-white"
            >
              Get started
            </Link>
            <Link
              to={SocialLinks.WHATSAPP}
              target="_blank"
              className="flex h-12 w-full max-w-[268px] items-center justify-center gap-2 rounded-full border-none bg-white font-medium text-primary"
              rel="noreferrer"
            >
              <WhatsApp className="h-6 fill-primary" />
              <span>Add us on WhatsApp</span>
            </Link>
          </div>
        </div>
      </header>
      <div className="w-full lg:-mt-48">
        <IntroCarousel />
      </div>
      <Partners />
      <Services companyName={partnerDetails.title} type={queryType} />
      <UnauthenticatedFooter />
    </div>
  );
};
