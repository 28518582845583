import { marked } from 'marked';
import React, { useEffect, useRef, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { twMerge } from 'tailwind-merge';

import { useWizard } from '@components/MoveWizard/wizards';
import { Spinner } from '@components/Spinner';

import { ApiRoutes, WizardSteps } from '@constants/index';

import { selectProfile } from '@features/profile/profile.ts';
import { Address } from '@features/profile/profile.types';

import { useToast } from '@hooks/useToast';

import HomeIntelligenceSquare from '@images/hi-square.png';
import HomeIntelligenceLogo from '@images/home-intelligence.png';
import { ArrowTop, Broadband, Insurance, Removals, Snow } from '@images/icons';


import { convertAddressToSingleLine } from '@utils/convertAddress';
import customAxios from '@utils/customAxios';


type Message = {
  role: 'user' | 'assistant';
  content: string;
  createdAt: number;
};

const PremadePrompt = ({ text, promptText, icon, onSend }: any) => (
  <button
    className="mx-auto w-full rounded-xl bg-home px-4 py-2 text-left text-white transition-all duration-300 hover:bg-homeDark"
    onClick={() => onSend(promptText)}
  >
    <span className="float-right pb-5 pl-5">{icon || <Snow className="h-5 w-5 fill-white" />}</span>
    {text}
  </button>
);

export const HomeIntelligencePage = () => {
  const [userMessage, setUserMessage] = useState('');
  const [messages, setMessages] = useState<Message[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingHistory, setIsLoadingHistory] = useState(true);
  const profile = useSelector(selectProfile, shallowEqual)?.value;
  const { dispatchErrorToast } = useToast();
  const messageListRef = useRef<HTMLDivElement>(null);

  const initialProperty = profile?.properties?.[0];

  const steps = useWizard(initialProperty as Address);

  const scrollToBottom = () => {
    if (messageListRef.current) {
      messageListRef.current.scrollTop = messageListRef.current.scrollHeight;
    }
  };

  const handleSendMessage = async (message: string) => {
    const newMessage: Message = {
      role: 'user',
      content: message,
      createdAt: Date.now(),
    };

    setMessages(prevMessages => [...prevMessages, newMessage]);
    setUserMessage('');
    setIsLoading(true);

    try {
      const response = await customAxios.post(ApiRoutes.CHAT, {
        withCredentials: true,
        message,
      });

      if (response.data) {
        setMessages(prevMessages => [
          ...prevMessages,
          {
            role: 'assistant',
            content: response.data,
            createdAt: Date.now(),
          },
        ]);
      }
    } catch (error) {
      console.error('error', error);
      dispatchErrorToast('Something went wrong. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const fetchChatHistory = async () => {
      try {
        const response = await customAxios.get(ApiRoutes.CHAT_HISTORY, {
          withCredentials: true,
        });
        const data = response.data as Message[];

        if (data) {
          const sortedData = data
            .sort((a, b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime())
            .map(message => ({
              role: message.role,
              content: message.content,
              createdAt: message.createdAt * 1000,
            }));

          setMessages(sortedData);
          setTimeout(scrollToBottom, 100);
        }
      } catch (error) {
        console.error('Failed to fetch chat history:', error);
        dispatchErrorToast('Failed to load chat history. Please try again.');
      } finally {
        setIsLoadingHistory(false);
      }
    };

    fetchChatHistory();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!isLoadingHistory) {
      scrollToBottom();
    }
  }, [messages, isLoadingHistory]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!userMessage.trim()) return;
    await handleSendMessage(userMessage);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleSubmit(e as unknown as React.FormEvent);
    }
  };

  return (
    <section className="mx-auto mt-10 flex h-[calc(100vh-120px)] w-full max-w-[1400px] flex-col overflow-hidden border-x border-t border-gray-300 bg-white shadow-homeLargeLight">
      <header className="flex w-full shrink-0 flex-col gap-4 border-b border-gray-300 p-4">
        <img src={HomeIntelligenceLogo} alt="Home Intelligence" className="mx-auto max-w-xs" />
      </header>

      <div className="grid h-full grid-cols-1 md:grid-cols-4">
        <section className="col-span-1 flex flex-col px-3 pt-5 md:col-span-3 md:px-5">
          <div className="relative flex-1">
            {isLoadingHistory ? (
              <div className="flex h-full items-center justify-center">
                <Spinner colour="black" />
              </div>
            ) : (
              <div
                id="message-list"
                ref={messageListRef}
                className="no-scrollbar absolute inset-0 space-y-8 overflow-y-auto px-4 py-8 md:px-8"
              >
                {messages.map((message, index) => (
                  <div
                    key={index}
                    id="message"
                    className={`flex flex-col gap-2 ${
                      message.role === 'user' ? 'items-end' : 'items-start'
                    }`}
                  >
                    <div
                      className={twMerge(
                        message.role === 'user'
                          ? 'bg-home text-white'
                          : 'bg-gray-100 text-gray-800',
                        'max-w-[85vw] whitespace-pre-wrap rounded-lg px-4 py-3 leading-relaxed shadow-lg shadow-gray-50 md:max-w-md'
                      )}
                    >
                      <div
                        className="flex flex-col gap-5 whitespace-normal"
                        dangerouslySetInnerHTML={{
                          __html: marked.parse(message.content),
                        }}
                      />
                    </div>
                    <small className="mt-1 text-xs text-gray-500">
                      {new Date(message.createdAt).toLocaleString()}
                    </small>
                  </div>
                ))}
                {isLoading && (
                  <div className="flex items-center gap-1 px-5 font-semibold text-gray-800">
                    <img
                      src={HomeIntelligenceSquare}
                      alt="Home Intelligence"
                      className="mt-1.5 h-10 w-10"
                    />
                    <span>Replying</span>
                    <div className="loading-dots flex items-end">
                      <span className="dot"></span>
                      <span className="dot"></span>
                      <span className="dot"></span>
                    </div>
                  </div>
                )}
              </div>
            )}
            <div className="pointer-events-none absolute bottom-0 left-0 right-0 h-8 bg-gradient-to-t from-white to-transparent" />
            <div className="pointer-events-none absolute left-0 right-0 top-0 h-8 bg-gradient-to-t from-transparent to-white" />
          </div>

          <form
            onSubmit={handleSubmit}
            className={twMerge(
              'mx-auto my-4 flex w-[calc(100%-16px)] items-end gap-3 overflow-hidden rounded-2xl border-2 bg-white p-2 md:my-8 md:w-[calc(100%-32px)] md:gap-5 md:p-3',
              userMessage ? 'border-gray-400' : 'border-gray-300'
            )}
          >
            <textarea
              value={userMessage}
              onChange={e => setUserMessage(e.target.value)}
              onKeyDown={handleKeyDown}
              className="h-20 w-full p-2 outline-none md:h-28 md:p-4"
              placeholder="Ask me anything about your move..."
            />
            <button
              type="submit"
              disabled={!userMessage}
              className={twMerge(
                'rounded-2xl bg-home px-3 py-2 text-white transition-all duration-300 md:px-4',
                userMessage ? 'opacity-100' : 'opacity-25'
              )}
            >
              <ArrowTop className="h-5 w-5 fill-white md:h-6 md:w-6" />
            </button>
          </form>
        </section>

        <aside className="hidden overflow-scroll border-l border-gray-300 bg-gray-50 p-5 md:block">
          <div className="no-scrollbar mx-auto flex flex-col items-center justify-center gap-5">
            <span>
              <b>Not sure what to ask?</b> Here are some questions you might find useful
            </span>
            <PremadePrompt
              text="Provide a list of all the people and services I need to notify about my change of address."
              promptText="Please provide a list of all the people and services I need to notify about my change of address."
              onSend={handleSendMessage}
            />
            {profile?.properties[0] && (
              <>
                <PremadePrompt
                  text="Help me create a personalized moving checklist with timelines and essential tasks."
                  promptText={`Please help me create a personalized moving checklist with timelines and essential tasks. I am moving to ${convertAddressToSingleLine(profile?.properties[0])} as a ${profile?.properties[0].status.toLocaleLowerCase()}`}
                  onSend={handleSendMessage}
                />
                {steps.find(step => step.id === WizardSteps.removals)?.tasks[0]?.status !==
                  'COMPLETED' && (
                  <>
                    <PremadePrompt
                      text="What are the best tips for packing and organizing my belongings efficiently?"
                      promptText="What are the best tips for packing and organizing my belongings efficiently?"
                      icon={<Removals className="h-5 w-5 fill-white" />}
                      onSend={handleSendMessage}
                    />
                    <PremadePrompt
                      text="What should I look for when hiring movers."
                      promptText="Assist me in comparing moving companies and understanding what to look for when hiring movers."
                      icon={<Removals className="h-5 w-5 fill-white" />}
                      onSend={handleSendMessage}
                    />
                  </>
                )}
                {steps.find(step => step.id === WizardSteps.getMoveReady)?.tasks[1]?.status !==
                  'COMPLETED' && (
                  <>
                    <PremadePrompt
                      text={`Find me the best broadband deal`}
                      promptText={`Find broadband quotes for ${profile?.properties?.[0]?.postcode ?? ''}, please`}
                      icon={<Broadband className="h-5 w-5 fill-white" />}
                      onSend={handleSendMessage}
                    />
                    <PremadePrompt
                      text={`How do I choose the best broadband deal?`}
                      promptText={`How do I choose the best broadband deal?`}
                      icon={<Broadband className="h-5 w-5 fill-white" />}
                      onSend={handleSendMessage}
                    />
                  </>
                )}
                {steps.find(step => step.id === WizardSteps.insurance)?.tasks[0]?.status !==
                  'COMPLETED' && (
                  <PremadePrompt
                    text={`Why do I need ${profile?.properties[0].status.toLocaleLowerCase()} insurance?`}
                    promptText={`Please explain the importance of ${profile?.properties[0].status.toLocaleLowerCase()} insurance to me and why I need it. My address is ${convertAddressToSingleLine(profile?.properties[0])}`}
                    icon={<Insurance className="h-5 w-5 fill-white" />}
                    onSend={handleSendMessage}
                  />
                )}
              </>
            )}
          </div>
        </aside>
      </div>
    </section>
  );
};
