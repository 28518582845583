import { AusFinancesSignpost, AusRemovalsSignpost } from '@components/Signpost';

import { Insurance, Removals } from '@images/icons';

export const FinancesCard = () => (
  <div className="flex flex-col items-start gap-4">
    <div className="flex items-center gap-3">
      <Insurance className="h-8 fill-black" />
      <h3 className="text-xl font-medium">Finance</h3>
    </div>
    <AusFinancesSignpost hideStats />
  </div>
);

export const RemovalsCard = () => (
  <div className="flex flex-col items-start gap-4">
    <div className="flex items-center gap-3">
      <Removals className="h-8 fill-black" />
      <h3 className="text-xl font-medium">Removals</h3>
    </div>
    <AusRemovalsSignpost />
  </div>
);
