import { Transition } from '@headlessui/react';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { twMerge } from 'tailwind-merge';

import { selectProfile } from '@features/profile/profile';
import { MoveType } from '@features/profile/profile.types';

import { isAustraliaBrand, PartnerBrand } from '@utils/getBrandingConfig';

import { AusWhatsAppTip } from './tips/ausTips';
import { AddToHomeScreenTip, StoreTip, WelcomeTip, WhatsAppTip } from './tips/baseTips';
import { BroadbandTip } from './tips/broadbandTip';
import { EnergyTip } from './tips/energyTip';
import { DocumentTip, InspectTip, MaintenanceTip } from './tips/tenantTips';
import { Tip } from './types';

export const QuickTips = () => {
  const { value: profile } = useSelector(selectProfile);
  const [showTip, setShowTip] = useState(true);

  const initialProperty = profile?.properties?.[0];
  const moveType = initialProperty?.status;

  const isAustralia =
    !!initialProperty?.partnerCode &&
    isAustraliaBrand(initialProperty?.partnerCode as PartnerBrand);

  const handleDismiss = () => {
    setShowTip(false);
  };

  const TIPS: Tip[] = isAustralia
    ? [
        WelcomeTip(initialProperty?.status),
        AddToHomeScreenTip(),
        ...(initialProperty?.whatsAppLink ? [AusWhatsAppTip(initialProperty.whatsAppLink)] : []),
      ]
    : [
        WelcomeTip(initialProperty?.status),
        ...(moveType === MoveType.TENANCY ? [InspectTip, DocumentTip, MaintenanceTip] : []),
        WhatsAppTip(initialProperty?.whatsAppLink),
        ...(!!initialProperty && !!initialProperty.postcode
          ? [EnergyTip(), BroadbandTip(initialProperty.postcode)]
          : []),
        StoreTip,
        AddToHomeScreenTip(),
      ];

  const [currentTipIndex, setCurrentTipIndex] = useState<number>(() => {
    const stored = localStorage.getItem('currentTip');
    const index = stored ? parseInt(stored, 10) : 0;
    return isNaN(index) || index < 0 || index >= TIPS.length ? 0 : index;
  });

  const onExited = () => {
    const nextTipIndex = (currentTipIndex + 1) % TIPS.length;
    setCurrentTipIndex(nextTipIndex);
    localStorage.setItem('currentTip', nextTipIndex.toString());
    setShowTip(true);
  };

  if (!moveType) return null;

  const visibleTip = TIPS[currentTipIndex];

  return (
    <div className="relative order-1 col-span-1 mx-auto h-60 w-full max-w-md sm:h-56 md:order-3 md:col-span-2 md:h-full md:max-w-full">
      <div className="absolute inset-0 rotate-2 transform rounded-2xl bg-white shadow-homeLargeLight md:rotate-3" />

      <Transition
        show={showTip}
        as="div"
        enter="transition-all duration-300 ease-in-out"
        enterFrom="-translate-x-8 opacity-0 rotate-[-6deg]"
        enterTo="translate-x-0 opacity-100 rotate-0"
        leave="transition-all duration-300 ease-in-out"
        leaveFrom="translate-x-0 opacity-100 rotate-0"
        leaveTo="translate-x-8 opacity-0 rotate-[6deg]"
        afterLeave={onExited}
        className={twMerge(
          'relative flex h-full flex-col items-start gap-3 rounded-2xl bg-white p-5 pt-4 shadow-homeLargeLight',
          visibleTip.loose && 'md:gap-5'
        )}
      >
        <div className="flex w-full items-center justify-between">
          <h5 className="text-2xl font-medium">
            {visibleTip.subtitle ? visibleTip.subtitle : 'Quick tip'}
          </h5>
          <button className="p-1 underline hover:no-underline" onClick={handleDismiss}>
            Next
          </button>
        </div>
        <div className="flex w-full items-center gap-3">
          <div
            className={twMerge(
              'flex h-10 min-w-10 basis-10 items-center justify-center rounded-full',
              visibleTip.iconBackground
            )}
          >
            {visibleTip.icon}
          </div>
          <p className="text-lg">{visibleTip.text}</p>
        </div>
        {visibleTip.children}
      </Transition>
    </div>
  );
};
