import Autoplay from 'embla-carousel-autoplay';
import useEmblaCarousel from 'embla-carousel-react';
import React, { useCallback, useEffect } from 'react';

import {
  NewSlideOne,
  NewSlideTwo,
  NewSlideThree,
  NewSlideFour,
  NewSlideFive,
  NewSlideSix,
  NewSlideSeven,
  NewSlideEight,
  NewSlideNine,
} from './slides';

export const IntroCarousel = () => {
  const [activeSlide, setActiveSlide] = React.useState(1);
  const [emblaRef, emblaApi] = useEmblaCarousel(
    {
      loop: true,
    },
    [Autoplay({ playOnInit: true, delay: 3000, stopOnInteraction: false })]
  );

  const logSlidesInView = useCallback((emblaApi: any) => {
    setActiveSlide(emblaApi.selectedScrollSnap() + 1);
  }, []);

  useEffect(() => {
    if (emblaApi) emblaApi.on('select', logSlidesInView);
  }, [emblaApi, logSlidesInView]);

  return (
    <div className="w-full pb-16">
      <div
        className="overflow-hidden"
        ref={emblaRef}
        style={{ transitionTimingFunction: 'ease-in-out !important' }}
      >
        <div className="flex">
          <NewSlideOne isActive={activeSlide === 1} />
          <NewSlideTwo isActive={activeSlide === 2} />
          <NewSlideThree isActive={activeSlide === 3} />
          <NewSlideFour isActive={activeSlide === 4} />
          <NewSlideFive isActive={activeSlide === 5} />
          <NewSlideSix isActive={activeSlide === 6} />
          <NewSlideSeven isActive={activeSlide === 7} />
          <NewSlideEight isActive={activeSlide === 8} />
          <NewSlideNine isActive={activeSlide === 9} />
        </div>
      </div>
    </div>
  );
};
