import { Link } from 'react-router-dom';

import { ProductLinks, Routes } from '@constants/index';

import { Bulb } from '@images/icons';

export const EnergyTip = () => ({
  id: 'utilities',
  isVisible: true,
  text: 'Set up your utilities',
  iconBackground: 'bg-home',
  icon: <Bulb className="h-6 fill-white" />,
  children: (
    <div className="flex flex-col items-start gap-2 sm:gap-0 md:gap-4">
      <p>
        Home can help find the perfect tariff for your place. And even makes switching a breeze.
      </p>
      <Link
        to={ProductLinks.ENERGY}
        target="_blank"
        className="button-animate rounded-xl px-10 py-2 font-medium"
        rel="noreferrer"
      >
        Set up my energy
      </Link>
    </div>
  ),
});
