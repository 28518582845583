import { Link } from 'react-router-dom';

import { WhatsApp } from '@images/icons';

export const AusWhatsAppTip = (whatsAppLink: string) => ({
  id: 'whatsapp',
  isVisible: true,
  text: 'Join the WhatsApp group for your sale',
  iconBackground: 'bg-[#27D045]',
  icon: <WhatsApp className="ml-0.5 mt-0.5 h-6 fill-white" />,
  loose: true,
  children: (
    <Link
      to={whatsAppLink}
      target="_blank"
      className="button-animate rounded-xl px-10 py-3 font-medium"
      rel="noreferrer"
    >
      Join WhatsApp group
    </Link>
  ),
});
