import { zodResolver } from '@hookform/resolvers/zod';
import * as Sentry from '@sentry/react';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { z } from 'zod';

import { FloatingLabelInput } from '@components/ReactHookForm/FloatingLabelInput';
import { SelectField } from '@components/ReactHookForm/Select/SelectField';

import { selectProfile } from '@features/profile/profile.ts';

import { ReactComponent as Home } from '@images/home-v1.svg';
import { Conveyancing, Envelope, Phone, Tick } from '@images/icons';
import { ReactComponent as PoweredByMovemnt } from '@images/powered-by-movemnt.svg';
import { ReactComponent as SRA } from '@images/solicitors-regulation-authority.svg';

import {
  Address,
  HomeQuote,
  postcodeRegExp,
  showLogo,
  whyInstructDetailed,
} from '@pages/services/Conveyancing/index.tsx';
import { toggleSwitch } from '@pages/services/Conveyancing/toggleSwitch.tsx';

import customAxios from '@utils/customAxios.tsx';

export const Instruction = React.memo(
  ({
    selectedQuote,
    sessionId,
    quoteIds,
    correspondenceAddress,
    setIsSubmitting,
  }: {
    selectedQuote: HomeQuote;
    sessionId: string;
    quoteIds: string[];
    correspondenceAddress: Address | undefined;
    setIsSubmitting: (bool: boolean) => any;
  }) => {
    const profile = useSelector(selectProfile);
    const firstName = profile?.value?.customer?.name?.firstName ?? '';
    const lastName = profile?.value?.customer?.name?.lastName ?? '';
    const email = profile?.value?.customer?.email ?? '';
    const phone = profile?.value?.customer?.phone ?? '';

    type Instruction = {
      instructionDetails:
        | {
            firstName: string;
            lastName: string;
            email: string;
            phone: string;
            correspondenceAddress: Address;
            title: string;
            serviceTermsConsent: boolean;
          }
        | undefined;
    };

    const {
      register,
      handleSubmit,
      formState: { errors },
    } = useForm<Instruction>({
      mode: 'onBlur',
      resolver: async (values, context, options) => {
        const createResolver = zodResolver(
          z.object({
            instructionDetails: z.object({
              firstName: z.string(),
              lastName: z.string(),
              email: z.string().email('Invalid email').min(1, 'Email is required'),
              phone: z.string().min(10, 'Phone is required'),
              correspondenceAddress: z.object({
                line1: z.string().min(1, 'Address line 1 required'),
                line2: z.string().optional(),
                city: z.string().min(1, 'City is required'),
                country: z.string().optional(),
                postcode: z
                  .string()
                  .min(1, 'Moving from postcode is required')
                  .regex(postcodeRegExp, 'Invalid postcode'),
              }),
              title: z.enum(['Mr', 'Mrs', 'Ms']),
              serviceTermsConsent: z.boolean(),
            }),
          })
        );
        const promise = createResolver(values, context, options);
        // promise.then(res => console.log(res));
        return promise;
      },
      defaultValues: {
        instructionDetails: {
          firstName,
          lastName,
          email,
          phone,
          correspondenceAddress,
          title: 'Mr',
          serviceTermsConsent: false,
        },
      },
    });

    const [showInstructionSuccess, setShowInstructionSuccess] = useState(false);
    const [showError, setShowError] = useState(false);

    async function onSubmit(data: Instruction) {
      if (data.instructionDetails?.serviceTermsConsent) {
        setIsSubmitting(true);
        try {
          const response = await customAxios('/api/home/conveyancing/instruct', {
            method: 'POST',
            data: {
              sessionId,
              quoteIds: quoteIds,
              address: data.instructionDetails.correspondenceAddress,
              person: {
                title: data.instructionDetails.title,
                firstName: data.instructionDetails.firstName,
                lastName: data.instructionDetails.lastName,
                email: data.instructionDetails.email,
                phone: data.instructionDetails.phone,
                consent: data.instructionDetails.serviceTermsConsent,
              },
            },
          });
          const status = response.status;
          setIsSubmitting(false);
          if (status === 200) {
            setShowInstructionSuccess(true);
          }
        } catch (e) {
          Sentry.captureException(e);
          setIsSubmitting(false);
          setShowError(true);
        }
      } else {
        // console.error('No consent to do this');
      }
    }

    return (
      <div>
        <div className={'flex'}>
          <div
            className={
              'bg-white lg:flex lg:w-3/5 lg:max-w-[1256px] lg:flex-col lg:items-center lg:rounded-2xl lg:px-12 lg:py-8'
            }
          >
            <div className={'flex flex-col gap-y-4 lg:w-3/4'}>
              <div className={'text-center text-xl font-medium'}>Your costs</div>
              <div className={'text-lg font-medium'}>Legal transaction fees</div>
              {selectedQuote.sale ? (
                <div>
                  <div className={'mb-2 font-medium underline'}>Sale</div>
                  {selectedQuote.sale.fees.map(f => (
                    <div className={'flex justify-between'}>
                      <div className={'self-start'}>{f.name}:</div>
                      <div className={'self-end'}>£{f.netValue}</div>
                    </div>
                  ))}
                </div>
              ) : null}
              {selectedQuote.purchase ? (
                <div>
                  <div className={'mb-2 font-medium underline'}>Purchase</div>
                  {selectedQuote.purchase.fees.map(f => (
                    <div className={'flex justify-between'}>
                      <div className={'self-start'}>{f.name}:</div>
                      <div className={'self-end'}>£{f.netValue}</div>
                    </div>
                  ))}
                </div>
              ) : null}
              <div className={'mt-4 text-lg font-medium'}>Disbursements</div>
              {selectedQuote.sale ? (
                <div>
                  <div className={'mb-2 font-medium underline'}>Sale</div>
                  {selectedQuote.sale.disbursements.map(f => (
                    <div className={'flex justify-between'}>
                      <div className={'self-start'}>{f.name}:</div>
                      <div className={'self-end'}>£{f.netValue}</div>
                    </div>
                  ))}
                </div>
              ) : null}
              {selectedQuote.purchase ? (
                <div>
                  <div className={'mb-2 font-medium underline'}>Purchase</div>
                  {selectedQuote.purchase.disbursements.map(f => (
                    <div className={'flex justify-between'}>
                      <div className={'self-start'}>{f.name}:</div>
                      <div className={'self-end'}>£{f.netValue}</div>
                    </div>
                  ))}
                </div>
              ) : null}
              <div className={'mt-4 flex justify-between'}>
                <div className={'self-start text-lg font-medium'}>VAT:</div>
                <div className={'self-end'}>£{selectedQuote.totalVat.toFixed(2)}</div>
              </div>
              <div className={'m-auto my-4 w-[95%] border-[0.5px] border-black'}></div>
              <div className={'flex justify-between'}>
                <div className={'self-start text-xl font-medium'}>Total Conveyancing fee:</div>
                <div className={'self-end'}>£{selectedQuote.totalCost.toFixed(2)}</div>
              </div>
              <div className={'flex items-center'}>
                <SRA width={'60px'} />
                <div className={'ml-4'}>
                  Regulated by the Solicitors Regulation Authority (5926234)&nbsp;
                  <Link
                    className={'underline'}
                    referrerPolicy={'no-referrer'}
                    target={'_blank'}
                    to={'https://www.sra.org.uk/'}
                    rel="noreferrer"
                  >
                    Learn more
                  </Link>
                </div>
              </div>
            </div>
            <div className={'flex flex-col lg:w-3/4'}>
              <div className={'m-auto my-16 w-full border-[0.5px] border-gray-400'}></div>
              <div className={'flex flex-col'}>
                <div className={'text-center text-xl font-medium'}>Start instructing</div>
                <div className={'mt-4 text-center text-lg'}>
                  Tick one more thing off your list and start moving forwards with{' '}
                  {selectedQuote.name} today.
                </div>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="mt-4 flex w-full flex-col items-center gap-y-4">
                    <SelectField
                      styles="w-full"
                      options={[
                        { key: 'Mr', value: 'Mr' },
                        { key: 'Mrs', value: 'Mrs' },
                        { key: 'Ms', value: 'Ms' },
                      ]}
                      id="instructionDetails.title"
                      label="Title"
                      required={true}
                      key={'instructionDetails.title'}
                      error={errors.instructionDetails?.title}
                      {...register('instructionDetails.title')}
                    />
                    <FloatingLabelInput
                      id="instructionDetails.firstName"
                      label="First name"
                      placeholder="first name"
                      required={true}
                      error={errors.instructionDetails?.firstName}
                      {...register('instructionDetails.firstName')}
                    />
                    <FloatingLabelInput
                      id="instructionDetails.lastName"
                      label="Last name"
                      placeholder="last name"
                      required={true}
                      error={errors.instructionDetails?.lastName}
                      {...register('instructionDetails.lastName')}
                    />
                    <FloatingLabelInput
                      id="instructionDetails.email"
                      label="Email"
                      placeholder="email"
                      required={true}
                      error={errors.instructionDetails?.email}
                      {...register('instructionDetails.email')}
                    />
                    <FloatingLabelInput
                      id="instructionDetails.phone"
                      label="Phone"
                      placeholder="+44..."
                      required={true}
                      error={errors.instructionDetails?.phone}
                      {...register('instructionDetails.phone')}
                    />
                    <div className={'self-start'}>Correspondence address</div>
                    <div className={'self-start text-sm'}>
                      This is where we will send any accompanying materials.
                    </div>
                    <FloatingLabelInput
                      id="instructionDetails.correspondenceAddress.line1"
                      label="Address Line 1"
                      placeholder="Address line 1"
                      required={true}
                      error={errors.instructionDetails?.correspondenceAddress?.line1}
                      {...register('instructionDetails.correspondenceAddress.line1')}
                    />
                    <FloatingLabelInput
                      id="saleDetails.address.line2"
                      label="Address Line 2"
                      placeholder="Address line 2"
                      error={errors.instructionDetails?.correspondenceAddress?.line2}
                      {...register('instructionDetails.correspondenceAddress.line2')}
                    />
                    <FloatingLabelInput
                      id="saleDetails.address.city"
                      label="City"
                      placeholder="City"
                      required={true}
                      error={errors.instructionDetails?.correspondenceAddress?.city}
                      {...register('instructionDetails.correspondenceAddress.city')}
                    />
                    <FloatingLabelInput
                      id="correspondenceAddress.correspondenceAddress.postcode"
                      label="Postcode"
                      placeholder="Postcode"
                      required={true}
                      error={errors.instructionDetails?.correspondenceAddress?.postcode}
                      {...register('instructionDetails.correspondenceAddress.postcode')}
                    />
                    <SelectField
                      options={[
                        { key: 'England', value: 'England' },
                        { key: 'Wales', value: 'Wales' },
                        { key: 'Ireland', value: 'Ireland' },
                        { key: 'Scotland', value: 'Scotland' },
                      ]}
                      styles={'w-full'}
                      id="saleDetails.address.country"
                      label="Country"
                      required={true}
                      error={errors.instructionDetails?.correspondenceAddress?.country}
                      {...register('instructionDetails.correspondenceAddress.country')}
                    />
                    <div className={'self-start'}>
                      {toggleSwitch(
                        { ...register('instructionDetails.serviceTermsConsent') },
                        'instructionDetails.serviceTermsConsent',
                        <div>
                          I consent to the&nbsp;
                          <Link
                            className={'self-start text-sm underline'}
                            rel={'noreferrer'}
                            target={'_blank'}
                            to={
                              'https://www.zoopla.co.uk/zoopla-transaction-network-terms-and-conditions/'
                            }
                          >
                            service terms
                          </Link>
                        </div>,
                        true
                      )}
                    </div>
                  </div>
                  <button
                    className={
                      'mt-4 w-fit cursor-pointer self-center rounded-xl bg-primary px-8 py-3 text-lg text-white transition-all hover:enabled:bg-gray-700 disabled:opacity-50'
                    }
                    type={'submit'}
                  >
                    Let's go
                  </button>
                  {showError ? (
                    <div className={'mt-2 w-full'}>
                      <div>
                        Something went wrong, please try again or speak to us through our live chat
                        now
                      </div>
                    </div>
                  ) : null}
                </form>
              </div>
              <div className={'m-auto my-16 w-full border-[0.5px] border-gray-400'}></div>
            </div>
          </div>
          <div className={'flex hidden w-2/5 flex-col gap-y-4 p-4 lg:flex lg:py-0'}>
            <PoweredByMovemnt className={'mb-4 fill-amber-50'} />
            {contactDetails()}
            {whyInstructDetailed()}
          </div>
        </div>

        <div className={'flex w-full flex-col gap-y-4 lg:mt-10 lg:hidden'}>
          <div className={'text-center text-lg lg:text-xl lg:font-medium'}>Why instruct:</div>
          <div className={'flex lg:justify-end'}>
            <div className={'hidden lg:block lg:w-[100px]'}>
              <Conveyancing />
            </div>
            <div className={'ml-5 flex flex-col lg:ml-20'}>
              <div className={'flex items-center'}>
                <Tick width={'25px'} />
                <div className={'ml-4 w-[75%]'}>
                  Track every step right here with real-time updates at every stage
                </div>
              </div>
              <div className={'flex items-center'}>
                <Tick width={'25px'} />
                <div className={'ml-4 w-[75%]'}>No move, no fee</div>
              </div>
              <div className={'flex items-center'}>
                <Tick width={'25px'} />
                <div className={'ml-4 w-[75%]'}>100,000+ conveyances a year</div>
              </div>
            </div>
          </div>
        </div>

        <div className={'lg:hidden'}>{contactDetails()}</div>

        {showInstructionSuccess ? (
          <div className={'fixed left-0 top-0 z-[99] h-screen w-full bg-home'}>
            <div className="my-20 flex w-full flex-col items-center gap-y-7">
              <section className={'w-full text-white md:w-[500px]'}>
                {selectedQuote ? (
                  <div className={'flex flex-col items-center gap-y-8 bg-home'}>
                    <div className={'flex items-center gap-x-4'}>
                      <Tick className={'w-10 fill-amber-50'} />
                      <div className={'text-xl font-medium'}>Thank you {firstName}!</div>
                    </div>
                    <div className={'text-center'}>
                      Congrats, that's one more thing ticked off your to do list!
                    </div>
                    {showLogo(selectedQuote)}
                    <div className={'text-lg font-medium'}>
                      You're now connected to a conveyancer.
                    </div>
                    <div className={'text-md text-center font-medium'}>What happens next</div>
                    <div className={'text-center'}>
                      Your conveyancer will be in touch shortly to start progressing your move.
                    </div>
                    <button
                      type="button"
                      className="text-md w-[80%] cursor-pointer rounded-xl bg-secondary px-8 py-3 font-medium text-black transition-all hover:enabled:bg-gray-700 disabled:opacity-50"
                      onClick={() => {
                        window.location.href = '/';
                      }}
                    >
                      View Conveyancing
                    </button>
                    <div></div>
                  </div>
                ) : (
                  <></>
                )}
              </section>
            </div>
          </div>
        ) : null}
      </div>
    );

    function contactDetails() {
      return (
        <div
          className={
            'mt-8 flex flex-col items-center gap-y-4 lg:mt-0 lg:items-start lg:rounded-2xl lg:bg-white lg:p-4'
          }
        >
          {selectedQuote.phone || selectedQuote.email ? (
            <div className={'text-lg lg:font-medium'}>Get in touch</div>
          ) : null}
          {selectedQuote.phone ? (
            <div className={'flex w-full items-center justify-center lg:justify-start'}>
              <Phone width={'30px'} className={'self-start'} />
              <div className={'ml-4'}>{selectedQuote.phone}</div>
            </div>
          ) : null}
          {selectedQuote.email ? (
            <div className={'flex w-full items-center justify-center lg:justify-start'}>
              <Envelope width={'30px'} className={'self-start'} />
              <div className={'ml-4'}>{selectedQuote.email}</div>
            </div>
          ) : null}
          <div className={'flex w-full items-center justify-center lg:justify-start'}>
            <Home width={'30px'} className={'self-start'} />
            <Link
              className={'ml-4 underline'}
              to={selectedQuote?.website}
              target="_blank"
              rel="noreferrer"
            >
              {selectedQuote?.website?.slice(0, 30) +
                (selectedQuote?.website?.length > 30 ? '...' : '')}
            </Link>
          </div>
        </div>
      );
    }
  }
);
