import { useSelector } from 'react-redux';

import { useWizardAus } from '@components/Australia/MoveWizardAus/wizards';

import { WizardSteps } from '@constants/index';

import { selectProfile } from '@features/profile/profile';
import { Address } from '@features/profile/profile.types';

import { Clipboard } from '@images/icons';

import { FinancesCard, RemovalsCard } from './cards';

export const NextStepsAus = () => {
  const { value: profile } = useSelector(selectProfile);

  const initialProperty = profile?.properties?.[0];

  const steps = useWizardAus(initialProperty as Address);

  const tasks = steps.flatMap(step => step.tasks);
  const firstIncompleteStep = tasks.findIndex(task => task.status !== 'COMPLETED');
  const index = steps.findIndex(step => step.id === tasks[firstIncompleteStep]?.step);
  const nextStep = steps[index];

  const moveType = profile?.properties?.[0]?.status;

  if (!moveType || !nextStep) return null;

  const TASKS: any[] = [
    {
      valid:
        steps.find(step => step.id === WizardSteps.contracts)?.tasks[0]?.status !== 'COMPLETED',
      component: <FinancesCard />,
    },
    {
      valid: steps.find(step => step.id === WizardSteps.removals)?.tasks[0]?.status !== 'COMPLETED',
      component: <RemovalsCard />,
    },
  ];

  const validTasks = TASKS.length > 0 ? TASKS.filter(task => task.valid) : [];

  if (validTasks.length === 0) return null;

  return (
    <section
      id="my-services"
      className="mx-auto flex w-full max-w-6xl flex-col items-center gap-6 pt-3"
    >
      <header className="flex w-full max-w-2xl justify-around gap-8">
        <h1 className="flex items-center gap-4 text-center text-2xl font-medium">
          <Clipboard className="h-10 fill-black" />
          <span className="mt-1">Next Steps</span>
        </h1>
      </header>
      <div className="grid w-full grid-cols-1 gap-8 md:grid-cols-2">
        <div className="grid-cols-1 rounded-3xl bg-white p-6 shadow-homeLargeLight">
          {validTasks[0].component}
        </div>
        {validTasks.length > 1 && (
          <div className="grid-cols-1 rounded-3xl bg-white p-6 shadow-homeLargeLight">
            {validTasks[1].component}
          </div>
        )}
      </div>
    </section>
  );
};
