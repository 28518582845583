import { LinkButton } from '@components/Button';

import { Broadband, Bulb, Conveyancing, Insurance, Pound, Removals } from '@images/icons';

type ServiceProps = {
  Icon: React.ElementType;
  title: string;
  description: string;
};

const Service = ({ Icon, title, description }: ServiceProps) => {
  return (
    <div className="h-full w-full gap-3 rounded-2xl bg-white px-5 py-14 shadow-homeLargeLight">
      <div className="flex h-24 w-32 items-center">
        <Icon className="h-14" />
      </div>
      <h3 className="mb-2 text-2xl font-medium">{title}</h3>
      <p className="text-lg">{description}</p>
    </div>
  );
};

type ServicesProps = {
  type: string;
  companyName: string;
};

export const Services = ({ type, companyName }: ServicesProps) => {
  const isRenter = type === 'renter';

  return (
    <section className="w-full bg-[#F6F6F6] px-6 pb-32 pt-20">
      <div className="mx-auto flex w-full max-w-7xl flex-col items-center gap-8">
        <h2 className="max-w-lg text-center text-4xl font-medium !leading-normal lg:text-[48px]">
          How we&apos;re helping {isRenter ? 'renters' : 'people'} move with {companyName}
        </h2>
        <p className="max-w-lg text-center text-lg">
          Get the best prices for all your home services and get set up for moving day without the
          stress.
        </p>
        {isRenter ? (
          <div className="grid w-full grid-cols-1 gap-8 py-10 sm:grid-cols-2 lg:grid-cols-4">
            <Service
              Icon={Insurance}
              title="Renter Insurance"
              description="Home can't predict the future. So instead, it finds you the best cover from leading names."
            />
            <Service
              Icon={Bulb}
              title="Utilities"
              description="Home can help find the perfect tariff for your place and makes switching a breeze."
            />
            <Service
              Icon={Broadband}
              title="Broadband"
              description="Compare plans and get your home connected in just a few clicks with Home."
            />
            <Service
              Icon={Removals}
              title="Removals and van hire"
              description="Home's got lorry loads of choice. Pick one of our reliable partners and book them in."
            />
          </div>
        ) : (
          <div className="grid w-full grid-cols-1 gap-8 py-10 sm:grid-cols-2 lg:grid-cols-3">
            <Service
              Icon={Conveyancing}
              title="Conveyancing"
              description="Get a conveyancing quote in 60 seconds and instruct one of our trusted conveyancing partners"
            />
            <Service
              Icon={Pound}
              title="Finances"
              description="Access to thousands of mortgages from 70+ lenders without being charged a penny"
            />
            <Service
              Icon={Insurance}
              title="Insurance"
              description="Home can't predict the future. So instead, it finds you the best cover from leading names."
            />
            <Service
              Icon={Bulb}
              title="Utilities"
              description="Home can help find the perfect tariff for your place and makes switching a breeze."
            />
            <Service
              Icon={Broadband}
              title="Broadband"
              description="Compare plans and get your home connected in just a few clicks with Home."
            />
            <Service
              Icon={Removals}
              title="Removals & van hire"
              description="Home's got lorry loads of choice. Pick one of our reliable partners and book them in."
            />
          </div>
        )}
        <LinkButton href="/signup">Get started</LinkButton>
      </div>
    </section>
  );
};
